import moment from 'moment';

export const TTL = () => {
  return (
    <div className="page-wrap">
      <h1>Time To Lara:</h1>
      <h2>{findTimeToLara()}</h2>
      <br />
      <br />
      <br />
      <br />

      <span>
        {'Unlike a '}
        <a href="https://en.wikipedia.org/wiki/Time_to_live#DNS_records" target="_blank" rel="noreferrer">
          standard TTL
          </a>
        {', this shows how long until I can see your cute face again...'}
      </span>
      <br />
      <br />
      <br />
      <br />

      <a href="/" className="text-center">Back to home</a>
    </div>
  )
};

const findTimeToLara = (): string => {
  const now = moment();
  const laraTime = moment('2024-04-27');

  const daysUntil = laraTime.diff(now, 'days');

  if (daysUntil >= 60) return 'Less than two months 😉';
  if (daysUntil >= 30) return `💜 ${daysUntil} days left 💜`;
  if (daysUntil >= 7) return `💜 Only ${daysUntil} days! 💜`;
  if (daysUntil >= 1) return `💜 See you soon! 💜`;
  return '💜 The wait is finally over 💜';
};