export const NotFound = () => {
  return (
    <div className="not-found-wrap">
      <h1>Woah there buckaroo! Where do you think yer going?</h1>
      <br />
      <br />
      <br />
      <br />

      <a href="/">Back to home</a>
    </div>
  )
};